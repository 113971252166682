import React, { useState } from 'react'
import styled from 'styled-components'
import Spinner from '../Spinner'
import { Theme } from '../theme/theme.style'

interface FuncProps {
  id: number
  title: string
  edition: number
  video: string
  price: number
  url: string
}

const VideoCard = ({ id, title, edition, price, video, url }: FuncProps) => {
  const [isLoading, setIsLoading] = useState(true)

  return (
    // <Hyperlink href={url}>
    <CardContainer>
      <Content>
        <Title>{title}</Title>
        <div>
          <SubTitle></SubTitle>
          {/* <SubTitle>Edition of {edition}</SubTitle> */}
          {/* <SubTitle>{price} Ξ</SubTitle> */}
        </div>
      </Content>

      {isLoading && <Spinner />}
      <VideoContainer>
        <video
          src={video}
          autoPlay
          loop
          muted
          onLoadedData={() => setIsLoading(false)}
        />
      </VideoContainer>
    </CardContainer>
    // </Hyperlink>
  )
}

export default VideoCard

const Hyperlink = styled.a`
  color: ${Theme.colors.accent};

  &:hover {
    color: ${Theme.colors.minor};
  }
`

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: thin solid ${Theme.colors.accent_soft};

  &:hover {
    border: thin solid ${Theme.colors.accent};
    box-shadow: 0px 0px 7px 0.5px ${Theme.colors.minor};
  }

  @media (max-width: 768px) {
    border: thin solid ${Theme.colors.accent};
    box-shadow: 0px 0px 7px 0.5px ${Theme.colors.minor};
  }
`

const Title = styled.h4`
  margin: 0;
  color: ${Theme.colors.accent};
  font-weight: 500;
  letter-spacing: 1px;
`

const SubTitle = styled.p`
  margin: 0.5rem;
  color: ${Theme.colors.minor};
  font-size: 14px;
  letter-spacing: 0.5px;
`

const Content = styled.div`
  margin: 1rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
`

const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  video {
    justify-content: center;
    align-items: center;
    object-fit: contain;
    width: 100%;
    z-index: -1;
  }

  @media (max-width: 768px) {
    video {
      height: 50vh;
      object-fit: cover;
    }
  }
`
