import React from 'react'
import styled from 'styled-components'
import { Theme } from '../theme/theme.style'

interface FuncProps {
  title: string
  edition: number
  image: string
  price: number
  url: string
}

const ArtworkCard = ({ title, edition, price, image, url }: FuncProps) => {
  return (
    <CardContainer>
      {/* <Hyperlink href={url}> */}
      <Content>
        <Title>{title}</Title>
        <div>
          {/* <SubTitle>Edition of {edition}</SubTitle> */}
          {/* <SubTitle>{price} Ξ</SubTitle> */}
        </div>
      </Content>
      <ImageContainer>
        <img src={image} />
      </ImageContainer>
      {/* </Hyperlink> */}
    </CardContainer>
  )
}

export default ArtworkCard

const Hyperlink = styled.a`
  color: ${Theme.colors.accent};

  &:hover {
    color: ${Theme.colors.minor};
  }
`

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;

  border: thin solid ${Theme.colors.accent_soft};
  &:hover {
    border: thin solid ${Theme.colors.accent};
    box-shadow: 0px 0px 7px 0.5px ${Theme.colors.minor};
  }

  @media (max-width: 768px) {
    border: thin solid ${Theme.colors.accent};
    box-shadow: 0px 0px 7px 0.5px ${Theme.colors.minor};
  }
`

const Title = styled.h4`
  margin: 0;
  color: ${Theme.colors.accent};
  font-weight: 500;
  letter-spacing: 1px;
`

const SubTitle = styled.p`
  margin: 0.5rem;
  color: ${Theme.colors.minor};
  font-size: 14px;
  letter-spacing: 0.5px;
`

const Content = styled.div`
  margin: 1rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;

  img {
    justify-content: center;
    align-items: center;
    object-fit: contain;
    width: 100%;
  }
`
