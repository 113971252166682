// https://k-kad.s3.eu-west-2.amazonaws.com/collection/the-psiloformers/
import {
  Collection,
  CollectionKeys,
  MarketplacesKeys,
  StatusKeys,
} from '../store/type/collection.type'

export const collectionsData: Collection[] = [
  {
    [CollectionKeys.ID]: 'the-horizon-dweller',
    [CollectionKeys.TITLE]: 'The Horizon Dweller',
    [CollectionKeys.DESCRIPTION]: `At the hypothetical line where Earth meets Sky, vision craves pursuit. Granular trails and spirited hues stir the ethereal. The edge of sight is ever-expanding. A test to the tenacious. Recursive memories conquer the unreached.\n\nThe Horizon Dweller is a custom-trained Artificial Intelligence model, recursively generates static artworks and immersive moving images, custom-trained from a big dataset spanning 365 days of Nordic landscapes. The trained model is a generative adversarial network that uses an alternative generator architecture, borrowing from style transfer literature. It follows Progressive GAN in using a progressively growing training regime.\n\nThe project is supported by the Uniarts Helsinki's Academy Of Fine Arts and the Saastamoinen Foundation, Helsinki (FI).`,
    [CollectionKeys.YEAR]: 2023,
    [CollectionKeys.MAIN_VIDEO]:
      'https://k-kad.s3.eu-west-2.amazonaws.com/collection/the-horizon-dweller/1.mp4',
    [CollectionKeys.TAGS]: ['ai', 'nft'],
    [CollectionKeys.NUMBER_OF_ARTWORKS]: 106,
    [CollectionKeys.NUMBER_OF_EDITIONS]: 530,
    [CollectionKeys.STATUS]: StatusKeys.DEPLOYED,
    [CollectionKeys.SUPPORTED_BY]: [
      {
        name: 'Saastamoinen Foundation',
      },
      {
        name: "Helsinki's Academy Of Fine Arts",
      },
    ],
    [CollectionKeys.CONTRACT_ADDRESS]:
      '0x3ae28a0de91e6650e50ee809384ca98ca0fdcb74',
    [CollectionKeys.MARKETPLACES]: [
      {
        [MarketplacesKeys.NAME]: 'Etherscan',
        [MarketplacesKeys.URL]:
          'https://etherscan.io/address/0x3Ae28a0de91E6650E50ee809384Ca98cA0fdCB74',
      },
      {
        [MarketplacesKeys.NAME]: 'Opensea',
        [MarketplacesKeys.URL]:
          'https://opensea.io/collection/k-kad-the-horizon-dweller',
      },
    ],
    [CollectionKeys.ARTWORKS]:
      'https://k-kad.s3.eu-west-2.amazonaws.com/collection/the-horizon-dweller',
  },
  {
    [CollectionKeys.ID]: 'the-terraformers',
    [CollectionKeys.STATUS]: StatusKeys.DEPLOYED,
    [CollectionKeys.TITLE]: 'The Terraformers',
    [CollectionKeys.DESCRIPTION]: `Through the looking glass, one is none but thousands of lifeforms. In constant dynamics, they morph through interactions, form and reform. A complex ecosystem designed to evolve. To discern one’s hunger, to capture one’s truth. A giant culturing flask, uniquely individual, with no other will than one’s own. \n\nThe Terraformers is a custom-trained Artificial intelligence model on a on two big datasets – microscopic fungal colonies, and real human portraits. The trained model is a generative adversarial network that uses an alternative generator architecture, borrowing from style transfer literature. It follows Progressive GAN in using a progressively growing training regime.\n\nThe project is supported by The Uniarts Helsinki's Academy Of Fine Arts and the Saastamoinen Foundation, Helsinki (FI).`,
    [CollectionKeys.YEAR]: 2023,
    [CollectionKeys.NUMBER_OF_ARTWORKS]: 106,
    [CollectionKeys.NUMBER_OF_EDITIONS]: 530,
    [CollectionKeys.MAIN_VIDEO]:
      'https://k-kad.s3.eu-west-2.amazonaws.com/collection/the-psiloformers/1.mp4',
    [CollectionKeys.TAGS]: ['ai', 'nft'],
    [CollectionKeys.SUPPORTED_BY]: [
      {
        name: 'Saastamoinen Foundation',
      },
      {
        name: "Helsinki's Academy Of Fine Arts",
      },
    ],
    [CollectionKeys.CONTRACT_ADDRESS]:
      '0x39087BdFC69A09Ab940B55A92D89A534e4CD20e1',
    [CollectionKeys.MARKETPLACES]: [
      {
        [MarketplacesKeys.NAME]: 'Etherscan',
        [MarketplacesKeys.URL]:
          'https://etherscan.io/address/0x39087BdFC69A09Ab940B55A92D89A534e4CD20e1',
      },
      // {
      //   [MarketplacesKeys.NAME]: 'Opensea',
      //   [MarketplacesKeys.URL]:
      //     'https://opensea.io/collection/k-kad-the-horizon-dweller',
      // },
    ],
    [CollectionKeys.ARTWORKS]:
      'https://k-kad.s3.eu-west-2.amazonaws.com/collection/the-psiloformers',
  },
  {
    [CollectionKeys.ID]: 'the-molten-pearl',
    [CollectionKeys.STATUS]: StatusKeys.DEPLOYED,
    [CollectionKeys.TITLE]: 'The Molten Pearl',
    [CollectionKeys.DESCRIPTION]: `Underneath the Earth’s radiance, its depths are yet unexplored. Upon careful listening, its innermost trembles, never ceases to grow. One millimetre a year, quicker on the Eastern hemisphere. To even out the asymmetric core, gravity rounds it into a sphere. The Earth is safe from tipping and holds its rotational course.\n\nThe Molten Pearl is a custom-trained Artificial Intelligence model, that draws on open-source datasets of photographs of Icelandic volcanoes, NASA outer space imagery of the Earth, and seismic and infrasound wave detection. The trained model is a generative adversarial network that uses an alternative generator architecture, borrowing from style transfer literature. It follows Progressive GAN in using a progressively growing training regime.\n\nThe project is supported by The Uniarts Helsinki's Academy Of Fine Arts and the Saastamoinen Foundation, Helsinki (FI).`,
    [CollectionKeys.YEAR]: 2023,
    [CollectionKeys.NUMBER_OF_ARTWORKS]: 106,
    [CollectionKeys.NUMBER_OF_EDITIONS]: 530,
    [CollectionKeys.MAIN_VIDEO]:
      'https://k-kad.s3.eu-west-2.amazonaws.com/collection/the-molten-pearl/6.mp4',
    [CollectionKeys.TAGS]: ['ai', 'nft'],
    [CollectionKeys.SUPPORTED_BY]: [
      {
        name: 'Saastamoinen Foundation',
      },
      {
        name: "Helsinki's Academy Of Fine Arts",
      },
    ],
    [CollectionKeys.MARKETPLACES]: [
      {
        [MarketplacesKeys.NAME]: 'Etherscan',
        [MarketplacesKeys.URL]:
          'https://etherscan.io/address/0x4b0777bBCdB663B8B2e7F401F3dc1526D5700Bc3',
      },
      // {
      //   [MarketplacesKeys.NAME]: 'Opensea',
      //   [MarketplacesKeys.URL]:
      //     'https://opensea.io/collection/k-kad-the-horizon-dweller',
      // },
    ],
    [CollectionKeys.ARTWORKS]:
      'https://k-kad.s3.eu-west-2.amazonaws.com/collection/the-molten-pearl',
  },
  {
    [CollectionKeys.ID]: 'the-solar-gazer',
    [CollectionKeys.STATUS]: StatusKeys.DEPLOYED,
    [CollectionKeys.TITLE]: 'The Solar Gazer',
    [CollectionKeys.DESCRIPTION]: `At the centre of the double spin, the solar glow touches the Earth in sequential rhythm. Ray by ray, it penetrates the eyes of the skin. Blink after blink, every moment is overclocked, fine-sliced, and stored in each cell. The cycle is lateral, precise, unerring. The past and future vanish in the eventual. In suspended oscillation, the eternal is in the present.\n\nThe Solar Gazer is a custom-trained Artificial Intelligence model, drawn from on a big photographic dataset spanning sunsets in different locations according to the sunset equation. It is inspired by theories of neuroscience and astronomy on the physiological perception of time. The trained model is a generative adversarial network that uses an alternative generator architecture, borrowing from style transfer literature. It follows Progressive GAN in using a progressively growing training regime.\n\nThe project is supported by The Uniarts Helsinki's Academy Of Fine Arts and the Saastamoinen Foundation, Helsinki (FI).`,
    [CollectionKeys.YEAR]: 2023,
    [CollectionKeys.NUMBER_OF_ARTWORKS]: 106,
    [CollectionKeys.NUMBER_OF_EDITIONS]: 530,
    [CollectionKeys.MAIN_VIDEO]:
      'https://k-kad.s3.eu-west-2.amazonaws.com/collection/the-solar-gazer/5.mp4',
    [CollectionKeys.TAGS]: ['ai', 'nft'],
    [CollectionKeys.SUPPORTED_BY]: [
      {
        name: 'Saastamoinen Foundation',
      },
      {
        name: "Helsinki's Academy Of Fine Arts",
      },
    ],
    [CollectionKeys.MARKETPLACES]: [
      {
        [MarketplacesKeys.NAME]: 'Etherscan',
        [MarketplacesKeys.URL]:
          'https://etherscan.io/address/0x5CcF439e2Ed96C2edF42459cf915D82E97A6c9D0',
      },
      // {
      //   [MarketplacesKeys.NAME]: 'Opensea',
      //   [MarketplacesKeys.URL]:
      //     'https://opensea.io/collection/k-kad-the-horizon-dweller',
      // },
    ],
    [CollectionKeys.ARTWORKS]:
      'https://k-kad.s3.eu-west-2.amazonaws.com/collection/the-solar-gazer',
  },
]
