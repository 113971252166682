import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Stripe, BackgroundVideo, Theme } from '../components'
function Main() {
  const video = 'https://k-kad.s3.eu-west-2.amazonaws.com/console_vid.mp4'
  const [isMuted, setIsMuted] = useState(true)

  const toggleMute = () => {
    setIsMuted(!isMuted)
  }

  return (
    <Section>
      <BackgroundVideo src={video} isMuted={isMuted} />

      <Stripe>
        <Button onClick={toggleMute}>
          <h3>K-KAD</h3>
        </Button>
      </Stripe>
    </Section>
  )
}

export default Main

export const Button = styled.button`
  border: thin solid ${Theme.colors.accent_soft};
  text-align: center;
  text-decoration: none;
  display: inline-block;
  padding: 15px 32px;
  color: ${Theme.colors.minor};
  background-color: black;

  &:active {
    border: thin solid ${Theme.colors.accent};
    box-shadow: 0px 0px 7px 0.5px ${Theme.colors.minor};
    /* background-color: #ad252180; */
  }
`
export const Section = styled.div`
  display: flex;
  width: 100%;
  flex-direction: 'row';
  align-items: 'center';
  justify-content: center;
`
