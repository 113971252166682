import styled from 'styled-components'
import { Theme } from '../theme/theme.style'

interface ContainerProps {
  width?: string
  height?: string
}

export const Container = styled.div<ContainerProps>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  padding: 0.5rem;
  margin: 0.5rem;
  border: thin solid ${Theme.colors.rnd};
`
