import { Organisation } from './organisation.type'

export enum ProjectKeys {
  ID = 'id',
  TITLE = 'title',
  DESCRIPTION = 'description',
  YEAR = 'year',
  MAIN_IMAGE = 'mainImage',
  IMAGES = 'images',
  VIDEO = 'video',
  SUPPORTED_BY = 'supportedBy',
  TAGS = 'tags',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
}

export enum ProjectType {
  EXHIBITION = 'exhibition',
  INSTALLATION = 'installation',
}

export interface Project {
  [ProjectKeys.ID]: string
  [ProjectKeys.TITLE]: string
  [ProjectKeys.DESCRIPTION]: string
  [ProjectKeys.YEAR]: number
  [ProjectKeys.MAIN_IMAGE]: string
  [ProjectKeys.IMAGES]: string[]
  [ProjectKeys.VIDEO]?: string[]
  [ProjectKeys.SUPPORTED_BY]: Organisation[]
  [ProjectKeys.TAGS]: string[]
}
