import React from 'react'
import styled from 'styled-components'
import { Theme } from '../theme/theme.style'

interface Props {
  items: Array<{ label: string; link: string }>
}

const MenuBar: React.FC<Props> = ({ items }) => {
  return (
    <NavbarContainer>
      <div></div>
      <NavbarList>
        {items.map(({ label, link }) => (
          <NavbarListItem key={label}>
            <NavbarLink href={link}>{label}</NavbarLink>
          </NavbarListItem>
        ))}
      </NavbarList>
    </NavbarContainer>
  )
}

export default MenuBar

const NavbarContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 25px;
  z-index: 999;
  background-color: ${Theme.colors.major};
  align-items: ${(props: any) => props.align};
  border: thin solid ${Theme.colors.accent_soft};

  &:hover {
    border: thin solid ${Theme.colors.accent};
    box-shadow: 0px 0px 7px 0.5px ${Theme.colors.minor};
  }

  @media (max-width: 768px) {
    display: none;
  }
`

const NavbarList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 15px;
  font-weight: 500;

  @media (max-width: 768px) {
    display: none;
  }
`

const NavbarListItem = styled.li`
  margin: 10px;
  padding: 10px;
`

const NavbarLink = styled.a`
  color: ${Theme.colors.minor};
  letter-spacing: 1px;

  &:hover {
    color: ${Theme.colors.accent};
  }
`
