import React from 'react'
import { Event, Press } from '../../../store/type/artist.type'
import { H3, Tag } from '../../index'
import { TD } from '../../theme/fonts.style'

interface FuncProps {
  title: string
  events?: Event[]
  press?: Press[]
}

function EventTable(props: FuncProps) {
  return (
    <div>
      {props.events != null && (
        <>
          <H3>| {props.title.toUpperCase()}</H3>
          <table>
            <tbody>
              {props.events.map((event, index) => {
                return (
                  <div key={index}>
                    <tr>
                      <td>
                        <Tag>| {event.year.toString()} |</Tag>
                      </td>
                      <TD>
                        {event.title +
                          `: ` +
                          event.venue +
                          `: ` +
                          event.country}
                      </TD>
                    </tr>
                  </div>
                )
              })}
            </tbody>
          </table>
        </>
      )}

      {props.press != null && (
        <>
          <H3>| {props.title.toUpperCase()}</H3>
          <table>
            <tbody>
              {props.press?.map((press, index) => {
                return (
                  <div key={index}>
                    <tr>
                      <td>
                        <Tag>| {press.year.toString()} |</Tag>
                      </td>
                      <TD>{press.publisher + ` : ` + press.title}</TD>
                    </tr>
                  </div>
                )
              })}
            </tbody>
          </table>
        </>
      )}
    </div>
  )
}

export default EventTable
