import styled from 'styled-components'

interface SectionProps {
  align: 'center' | 'left' | 'right'
  direction: 'row' | 'column'
}

export const Section = styled.div<SectionProps>`
  display: flex;
  width: 100%;
  margin-top: 50px;
  min-height: 100vh;
  flex-direction: ${(props: any) => props.direction};
  align-items: ${(props: any) => props.align};
  justify-content: center;
`
