import React from 'react'
import styled from 'styled-components'
import { Theme } from '../theme/theme.style'

interface FuncProps {
  title: string
  tags: string[]
}

const AboutProject = ({ title, tags }: FuncProps) => {
  return (
    <Container>
      <Title>{title}</Title>
    </Container>
  )
}

export default AboutProject

const Container = styled.div`
  margin-top: 2rem;
  border: thin solid ${Theme.colors.accent};
  box-shadow: 0px 0px 7px 0.5px ${Theme.colors.minor};
  width: 65%;
  text-align: center;

  @media (max-width: 768px) {
    margin-top: 1rem;
    width: 95%;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const Title = styled.h1`
  color: ${Theme.colors.minor};
  font-size: 28px;
  letter-spacing: 4px;
  // border: thin solid red;
  // margin: 0.5rem 0 0 0;

  @media (max-width: 768px) {
    font-size: 22px;
  }
`

const SubTitle = styled.p`
  color: ${Theme.colors.minor};
  font-size: 15px;
  margin-right: 10px;
`

const Hyperlink = styled.a`
  color: ${Theme.colors.accent};

  &:hover {
    color: ${Theme.colors.minor};
  }
`
