import React from 'react'
import styled from 'styled-components'
import { Theme } from '../theme/theme.style'

interface FuncProps {
  tags: string[]
}

const TagsComp = ({ tags }: FuncProps) => {
  return (
    <>
      <Container>
        <Content>
          {tags.map((tag, index) => (
            <Tag key={index}># {tag}</Tag>
          ))}
        </Content>
      </Container>
    </>
  )
}

export default TagsComp

const Container = styled.div`
  margin-top: 1rem;
  // border: thin solid red;
  // border: thin solid ${Theme.colors.accent};
  // box-shadow: 0px 0px 7px 0.5px ${Theme.colors.minor};
  width: 65%;
  text-align: center;

  @media (max-width: 768px) {
    margin-top: 1rem;
    width: 95%;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const Tag = styled.p`
  border: thin solid ${Theme.colors.accent};
  box-shadow: 0px 0px 7px 0.5px ${Theme.colors.minor};
  color: ${Theme.colors.accent};
  margin-right: 1rem;
  padding: 5px;
  @media (max-width: 768px) {
    font-size: 15px;
  }
`
