export const Theme = {
  colors: {
    major: 'black',
    minor: '#ffffffE6',
    accent: '#40e0d0',
    accent_soft: '#40e0d080',
    rnd: [
      // '#399FED80',
      // '#39ED7580',
      // '#E058D480',
      // '#E0BD2B80',
      // '#94380D80',
      // '#1C948880',
      // '#E0504C80',
      // '#E09C3680',
      // '#E0504C80',
      // '#4CA0E080',
      // '#E05B5880',
      // '#9C58E080',
      // '#E0642B80',
      // '#DEE03680',
      // '#AD252180',
      // '#2171AD80',
    ],
  },
  fontUrl:
    'https://fonts.googleapis.com/css2?family=Zen+Kaku+Gothic+New:wght@300;400;500;700;900&display=swap',
}
