import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Project, ProjectKeys } from '../../store/type/project.type'
import { Theme } from '../theme/theme.style'

interface FuncProps {
  project: Project
}

const ProjectCard = ({ project }: FuncProps) => {
  const navigate = useNavigate()

  return (
    <CardContainer
      onClick={() => navigate(`/project/${project[ProjectKeys.ID]}`)}
    >
      <Content>
        <Title>{project[ProjectKeys.TITLE]}</Title>
        <Tags>
          {project[ProjectKeys.SUPPORTED_BY].map((organisation, index) => (
            <>
              <Tag>| {organisation.name}</Tag>
            </>
          ))}
        </Tags>
      </Content>

      <Image
        src={project[ProjectKeys.MAIN_IMAGE]}
        alt={project[ProjectKeys.TITLE]}
      />
    </CardContainer>
  )
}

export default ProjectCard

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 15px 0;
  border: thin solid ${Theme.colors.accent_soft};

  &:hover {
    border: thin solid ${Theme.colors.accent};
    box-shadow: 0px 0px 7px 0.5px ${Theme.colors.minor};
    opacity: 100%;

    div {
      border: thin solid ${Theme.colors.accent};
      box-shadow: 0px 0px 7px 0.5px ${Theme.colors.minor};
      border-left: 0;
      border-right: 0;
      opacity: 100%;
    }

    img {
      border-right: thin solid ${Theme.colors.accent};
      box-shadow: 0px 0px 7px 0.5px ${Theme.colors.minor};
      opacity: 100%;
    }
  }

  @media (min-width: 768px) {
    max-width: 800px;
  }

  @media (max-width: 768px) {
    border: thin solid ${Theme.colors.accent};
    box-shadow: 0px 0px 7px 0.5px ${Theme.colors.minor};

    max-width: 350px;
  }
`

const Image = styled.img`
  width: 100%;
  border: thin solid ${Theme.colors.accent_soft};
  border-radius: 0;

  @media (min-width: 768px) {
    width: 65%;
  }
`

const Content = styled.div`
  width: 100%;
  padding: 16px;
  box-sizing: border-box;

  border: thin solid ${Theme.colors.accent_soft};
  border-left: 0;
  border-right: 0;
  border-radius: 0;

  @media (min-width: 768px) {
    width: 35%;
  }
`

const Title = styled.h4`
  margin: 0;
  margin-bottom: 10px;
  color: ${Theme.colors.accent};
  letter-spacing: 2px;
`

const Year = styled.p`
  margin: 0;
  font-size: 14px;
  color: ${Theme.colors.minor};
`

const Tags = styled.span`
  display: flex;
  flex-wrap: wrap;
`

const Tag = styled.span`
  color: ${Theme.colors.minor};
  padding: 8px 8px 0px 0;
  border-radius: 4px;
  font-weight: 100;
  font-size: 12px;
  letter-spacing: 1px;
`
