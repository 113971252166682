import React from 'react'
import HamburgerBar from './hamburgerBar'
import MenuBar from './menuBar'
import { artists } from '../../data/artists.data'

interface Props {
  items: Array<{ label: string; link: string }>
}

const Navbar: React.FC<Props> = ({ items }) => {
  const artist = artists[0]
  return (
    <>
      <MenuBar items={items} />
      <HamburgerBar items={items} links={artist.links} />
    </>
  )
}

export default Navbar
