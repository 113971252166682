import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import AboutProject from '../components/project/AboutProject'
import GalleryList from '../components/project/GalleryList'
import { projectsData } from '../data/projects.data'
import { Project, ProjectKeys } from '../store/type/project.type'
import Banner from '../components/project/Banner'
import Description from '../components/project/Description'
import TagsComp from '../components/project/TagsComp'
import { Theme } from '../components/theme/theme.style'
import VideoComponent from '../components/project/VideoComponent'
// import { Theme } from '../components'

const ProjectPage = () => {
  const { id } = useParams()
  const [project, setProject] = useState<Project>()

  const getProject = () => {
    const project = projectsData.find(
      (project) => project[ProjectKeys.ID] === id,
    ) as Project

    setProject(project)
  }

  useEffect(() => {
    getProject()
  }, [id])

  return (
    <Section>
      {project != null && (
        <>
          <AboutProject
            title={project[ProjectKeys.TITLE]}
            tags={project[ProjectKeys.TAGS]}
          />
          <TagsComp tags={project[ProjectKeys.TAGS]} />
          <Banner image={project[ProjectKeys.MAIN_IMAGE]} />
          <Description description={project[ProjectKeys.DESCRIPTION]} />
          {project[ProjectKeys.VIDEO]?.map((videoLink, index) => (
            <VideoComponent key={index} videoLink={videoLink} />
          ))}
          <GalleryList images={project[ProjectKeys.IMAGES]} />
        </>
      )}
    </Section>
  )
}

export default ProjectPage

const Section = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem 0;
  height: auto;
  margin-bottom: 4rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
