import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`

    * {
        box-sizing:border-box;
        transition: all 0.2s ease;
        border-radius: 4px;
        a{
            text-decoration: none;
        }
    }

    body {
        font-family: sans-serif,'Zen Kaku Gothic New';
        background-color: ${({ theme }: any) => theme.colors.major};
        margin: 0;
    }

    ::-webkit-scrollbar{
        width:5px;
    }

    ::-webkit-scrollbar-track{
        background: ${({ theme }) => theme.colors.major};
    }

    ::-webkit-scrollbar-thumb{
        background: ${({ theme }) => theme.colors.accent};
        border-radius:25px;
    }

    ::-webkit-scrollbar-thumb:hover{
        background: ${({ theme }) => theme.colors.accent};
    }
`

export const Main = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const Left = styled.div`
  display: flex;
  text-align: center;
  width: 10%;
  @media (max-width: 768px) {
    width: 100vw;
  }
`
export const Right = styled.div`
  display: flex;
  text-align: center;
  width: 90%;
  @media (max-width: 768px) {
    width: 100vw;
  }
`
