import React from 'react'
import styled from 'styled-components'
import { Theme } from './theme/theme.style'
import { motion } from 'framer-motion'

const Spinner = () => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '3rem 0',
      }}
    >
      <SpinnerContainer>
        <Circle
          animate={{ rotate: 360 }}
          transition={{
            repeat: Infinity,
            duration: 5,
            ease: 'linear',
          }}
        />
      </SpinnerContainer>
    </div>
  )
}

export default Spinner

const SpinnerContainer = styled.div`
  display: 'flex';
  position: relative;
  width: 2rem;
  height: 2rem;
`

const Circle = styled(motion.span)`
  display: block;
  width: 2rem;
  height: 2rem;
  border: 0.2rem solid ${Theme.colors.accent_soft};
  border-top: 0.2rem solid ${Theme.colors.accent};
  border-radius: 50%;
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0;
`
