import React from 'react'
import styled from 'styled-components'
import { Marketplace } from '../../store/type/collection.type'
import { Button } from '../theme/button.style'
import { Theme } from '../theme/theme.style'

interface FuncProps {
  title: string
  artworks: number
  editions: number
  description: string
  marketplaces?: Marketplace[]
}

const AboutCollection = ({
  title,
  artworks,
  editions,
  description,
  marketplaces,
}: FuncProps) => {
  return (
    <AboutContainer>
      <Content>
        <Title>{title}</Title>
        <SubTitle>
          {artworks} Artworks : 6 generative videos and 100 generative images
          {/* {artworks} Artworks, Editions of {editions} */}
        </SubTitle>
      </Content>

      <Pargraph>{description}</Pargraph>

      <Marketplaces>
        {marketplaces?.map((marketplace, index) => (
          <Button style={{ margin: '1rem' }} key={index}>
            <Hyperlink href={marketplace.url}>{marketplace.name}</Hyperlink>
          </Button>
        ))}
      </Marketplaces>
    </AboutContainer>
  )
}

export default AboutCollection

const Marketplaces = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const AboutContainer = styled.div`
  margin-top: 3rem;
  border: thin solid ${Theme.colors.accent};
  box-shadow: 0px 0px 7px 0.5px ${Theme.colors.minor};
  width: 75%;
  text-align: center;

  padding: 1rem;

  @media (max-width: 768px) {
    margin-top: 1rem;
    width: 95%;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Title = styled.h1`
  color: ${Theme.colors.accent};
  letter-spacing: 3px;
`

const SubTitle = styled.p`
  color: ${Theme.colors.minor};
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 2px;
`

const Pargraph = styled.p`
  padding: 0 1rem;
  color: ${Theme.colors.minor};
  font-size: 15px;
  line-height: 1.5;
  white-space: pre-wrap;
  text-align: center;
  font-weight: 250;
  letter-spacing: 1px;
`

const Hyperlink = styled.a`
  color: ${Theme.colors.accent};
  letter-spacing: 1px;

  &:hover {
    color: ${Theme.colors.minor};
  }
`
