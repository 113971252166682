import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Artwork } from '../../store/type/artwork.type'
import ArtworkCard from './ArtworkCard'

interface FuncProp {
  title: string
  bucket: string
  total: number
  contractAddress?: string | undefined
}

function ArtworkList({ title, bucket, total, contractAddress }: FuncProp) {
  const [artworks, setArtworks] = useState<Artwork[] | null>(null)

  const getArtworks = () => {
    const artworks: Artwork[] = []
    for (let i = 6; i < total + 6; i++) {
      const artworkNumber = i + 1

      artworks.push({
        title: `${title} #${artworkNumber}`,
        image: `${bucket}/${artworkNumber}.png`,
        edition: 5,
        url: `https://opensea.io/assets/ethereum/${contractAddress}/${artworkNumber}`,
      } as Artwork)
    }

    setArtworks(artworks)
  }

  useEffect(() => {
    getArtworks()
  }, [artworks])

  return (
    <Grid>
      {artworks?.map((artwork, index) => (
        <ArtworkCard
          key={index}
          title={artwork.title}
          edition={artwork.edition}
          image={artwork.image}
          price={0.25}
          url={artwork.url}
        />
      ))}
    </Grid>
  )
}

export default ArtworkList

export const Grid = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem 1rem;
  margin: 0rem 5rem;
  justify-content: center;
  align-content: center;
  @media (max-width: 1150px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 768px) {
    width: 95%;
    grid-template-columns: repeat(1, 1fr);
  }
`
