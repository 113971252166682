import React from 'react'
import styled from 'styled-components'

import { artists } from '../data/artists.data'
import { ProfileCard, ProfileCV } from '../components'

const Section = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 100vw;
  min-height: 100vh;
  justify-content: center;
  margin-top: 90px;
  margin-bottom: 4rem;
  @media (max-width: 1024px) {
    align-items: center;
    flex-direction: column;
  }
`

const About: React.FC = () => {
  const artist = artists[0]
  return (
    <Section>
      <ProfileCard image={artist.image} links={artist.links} />
      <ProfileCV artist={artist} />
    </Section>
  )
}

export default About
