import React from 'react'
import { Artist } from '../../../store/type/artist.type'
import { H3, P, Theme } from '../../index'
import EventTable from './eventTable'
import styled from 'styled-components'

interface ContainerProps {
  width?: string
  height?: string
}

interface FuncProps {
  artist: Artist
}

const Container = styled.div<ContainerProps>`
  width: 50%;
  height: auto;
  padding: 0.5rem;
  margin: 0.5rem;
  @media (max-width: 1210px) {
    width: 100vw;
  }
`

const Pargraph = styled.p`
  padding: 0 1rem;
  color: ${Theme.colors.minor};
  font-size: 15px;
  line-height: 1.5;
  white-space: pre-wrap;
  /* text-align: center; */
  font-weight: 250;
  letter-spacing: px;
`

function ProfileCV(props: FuncProps) {
  const artist = props.artist

  const exhibitions = artist.events.filter((event: any) =>
    event.tags.includes('exhibition'),
  )

  const performances = artist.events.filter((event: any) =>
    event.tags.includes('performance'),
  )
  const conferences = artist.events.filter(
    (event) =>
      event.tags.includes('conference') ||
      event.tags.includes('jury') ||
      event.tags.includes('talk'),
  )
  const fellowships = artist.events.filter(
    (event) =>
      event.tags.includes('fellowship') || event.tags.includes('grant'),
  )
  const press = artist.press

  return (
    <Container>
      <Title>K-KAD</Title>
      <Pargraph>{artist.biography}</Pargraph>
      <EventTable title="exhibitions" events={exhibitions} />
      <EventTable title="performances" events={performances} />
      <EventTable title="conferences" events={conferences} />
      <EventTable title="fellowships" events={fellowships} />
      <EventTable title="press" press={press} />
    </Container>
  )
}

export default ProfileCV

const Title = styled.h2`
  color: ${Theme.colors.accent};
  letter-spacing: 3px;
`
