import React from 'react'
import styled from 'styled-components'
import { Theme } from '../theme/theme.style'

interface FuncProps {
  image: string
}

const BannerComp = ({ image }: FuncProps) => {
  return (
    <BannerContainer>
      <img src={image} alt="" />
    </BannerContainer>
  )
}

export default BannerComp

const BannerContainer = styled.div`
  width: 50%;
  margin: 1rem;
  text-align: center;

  img {
    width: 100%;
    object-fit: cover;
    border: thin solid ${Theme.colors.accent};
    box-shadow: 0px 0px 7px 0.5px ${Theme.colors.minor};
    padding: 0.5rem;
  }

  @media (max-width: 768px) {
    width: 95%;
    height: auto;
    img {
      width: 100%;
      height: auto;
    }
  }
`
