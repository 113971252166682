import { Artist } from '../store/type/artist.type'

export const artists: Artist[] = [
  {
    id: '1',
    name: 'Khaled Kaddal',
    biography:
      // 'Khaled Kaddal is an Artist. His hybrid practice includes computational and generative media, sound performances and spatial installations. Finding inspitations from mythical motifs, his latest works contemplate human communication, politics of cybernetics and meta-borders.\n Selected Exhibitions and performances at Overgaden Institut for Samtidskunst, Copenhagen; Sharjah Art Foundation, Sharjah; Mosaic Rooms Gallery, London; Tokyo Metropolitan ArtMuseum, Tokyo; Science Gallery, London; Palazzo Mora Venice Biennale; TownHouse Gallery, Cairo; Cairotronica Symposium, Valletta 2018 European Capital of Culture; Cairo; Zentrum der Kunster Hellerau, Dresden; and Dilston Grove Gallery, London; 5th Biennale Internationale de Casablanca, Morocco. Visiting Fellow at the University of Oxford. Resident Fellow at Uniarts Helsinki. \n Kaddal studied Computer Science at AAST Egypt, and Sound Art at the University of the Arts London (UK).',
      // `do as Art !\n---\n\nKhaled Kaddal is an Interdisciplinary Artist and Tech-Engineer, resident in London. His hybrid practice includes computational and generative media, sound performances and spatial installations. Finding inspitations from mythical motifs, his latest works contemplate politics of communication, cybernetics and spatial borders.\n\nSelected Exhibitions and performances at Overgaden Institut for Samtidskunst, Copenhagen; Sharjah Art Foundation, Sharjah; Mosaic Rooms Gallery, London; Tokyo Metropolitan ArtMuseum, Tokyo; Science Gallery, London; Palazzo Mora Venice Biennale; TownHouse Gallery, Cairo; Cairotronica Symposium, Valletta 2018 European Capital of Culture; Cairo; Zentrum der Kunster Hellerau, Dresden; and Dilston Grove Gallery, London; 5th Biennale Internationale de Casablanca, Morocco. Visiting Fellow at the University of Oxford. Resident Fellow at Uniarts Helsinki.\n\nKaddal studied Computer Science at AAST Egypt, and Sound Art at the University of the Arts London (UK).\n\n`,
      `do as Art !\n---\nKhaled Kaddal is an Interdisciplinary Artist and Tech-Engineer at Consensys, Khaled's artistic journey seamlessly merges the realms of computation, generative media, sound performances, and spatial installations, reflecting his insatiable curiosity about the political intersections of technology, spirituality, and communication.\n\nKhaled's artistic odyssey has been an exploration of mythical motifs intertwined with the complexities of modern life. His creations resonate with profound reflections on cybernetics, body, and the ever-shifting boundaries of our physical and digital worlds.\n\nOne can trace Khaled Kaddal's creative footprints across the globe, as his works have been exhibited worldwide. Notable exhibitions and performances include Overgaden Institut for Samtidskunst in Copenhagen, the Sharjah Art Foundation in Sharjah, the Mosaic Rooms Gallery in London, and the Tokyo Metropolitan Art Museum in Tokyo. Khaled's art has also found its place at the Science Gallery in London, the Palazzo Mora during the Venice Biennale, the TownHouse Gallery in Cairo, and the Cairotronica Symposium during Valletta's reign as the 2018 European Capital of Culture.\n\nKhaled Kaddal has been honored as a Visiting Fellow at the University of Oxford, where he continues to push the boundaries of interdisciplinary art. Additionally, he has held the position of Resident Fellow at Uniarts Helsinki, further cementing his status in STEM Art.\n\nStudied at the University of the Arts London, where his research focused on Sonic Warfare, Belliphonic soundscapes, and the psychology of listening within sonic warfare. He also studied Computer Science at AAST Egypt.\n\n`,
    image: 'https://k-kad.s3.eu-west-2.amazonaws.com/images/khaledkaddal.jpeg',
    links: {
      email: 'khaledkaddal@gmail.com',
      twitter: 'https://twitter.com/KhaledKaddal',
      facebook: 'https://www.facebook.com/khaled.kaddal/',
      github: 'https://www.github.com/k-kaddal/',
      instagram: 'https://www.instagram.com/khaledkaddal/',
      vimeo: 'https://vimeo.com/khaledkaddal',
      linkedin: 'https://www.linkedin.com/in/khaledkadal/',
    },
    events: [
      {
        year: 2021,
        date: '18-19 Nov',
        tags: ['exhibition', 'Open Studio'],
        title: 'The Last Abode',
        venue: 'HIAP - Helsinki International Artist Programme',
        country: 'Helsinki, Finland',
      },
      {
        year: 2021,
        date: '16 Nov',
        tags: ['performance', 'talk'],
        title: 'On Sovereignty, I Reflect !',
        venue: 'University of the Arts Helsinki',
        country: 'Helsinki, Finland',
      },
      {
        year: 2021,
        date: '1 Sep - 30 Nov',
        tags: ['fellowship'],
        title: 'Air',
        venue: 'University of the Arts Helsinki',
        country: 'Helsinki, Finland',
      },
      {
        year: 202,
        date: '20 Sep - 21 May',
        tags: ['fellowship'],
        title: 'The Formula of giving Heart',
        venue: 'The University of Oxford',
        country: 'Oxford, United Kingdom',
      },
      {
        year: 2020,
        date: '24 Nov - 30 Nov',
        tags: ['exhibition'],
        title: 'ABJD',
        venue: 'Casablanca Biennale',
        country: 'Casablanca, Morroco',
      },
      {
        year: 2020,
        date: '21 Feb',
        tags: ['Jury'],
        title: 'Jury in Translation',
        venue: 'AA School of Architecture',
        country: 'London, United Kingdom',
      },
      {
        year: 2019,
        date: '19Sep - 11Jan',
        tags: ['exhibition'],
        title: 'On Edge: Living in an Age of Anxiety',
        venue: 'Science Gallery',
        country: 'London, United Kingdom',
      },
      {
        year: 2019,
        date: '23 - 29Jun',
        tags: ['exhibition'],
        title: 'Fungal Blooms',
        venue: 'Tokyo Metropolitan Art Museum',
        country: 'Tokyo, Japan',
      },
      {
        year: 2019,
        date: '01-16Jun',
        tags: ['exhibition'],
        title: 'Fungal Blooms',
        venue: 'Toshima Ecomusee Town',
        country: 'Tokyo, Japan',
      },
      {
        year: 2019,
        date: '24May',
        tags: ['performance'],
        title: 'Rapturous Inspirations',
        venue: 'Overgaden Institut for Samtidskunst',
        country: 'Copenhagen, Denmark',
      },
      {
        year: 2019,
        date: '02May',
        tags: ['performance'],
        title: 'The Sky is High, but!',
        venue: 'The Khalid Shoman Foundation',
        country: 'Amman, Jordan',
      },
      {
        year: 2019,
        date: '24Apr-01May',
        tags: ['conference'],
        title: 'Spatial Awareness',
        venue: 'Darat El-Funun Foundation',
        country: 'Amman, Jordan',
      },
      {
        year: 2019,
        date: '05Apr - 26Apr ',
        tags: ['exhibition'],
        title: 'To The Nostrils of Time',
        venue: 'Overgaden Institut for Samtidskunst',
        country: 'Copenhagen, Denmark',
      },
      {
        year: 2019,
        date: '24 Mar',
        tags: ['performance'],
        title: 'The Sky is High, but!',
        venue: 'Zentrum der Kunste Hellerau',
        country: 'Dresden, Germany',
      },
      {
        year: 2018,
        date: '13Dec - 15Dec',
        tags: ['conference'],
        title: 'Music, Sound and Built Environment',
        venue: 'American University of Cai',
        country: 'Cairo, Egypt',
      },
      {
        year: 2018,
        date: '21 Oct',
        tags: ['performance'],
        title: 'C0de.03',
        venue: 'Errant Sound Gallery',
        country: 'Berlin, Germany',
      },
      {
        year: 2018,
        date: '19Sep - 10 Nov',
        tags: ['exhibition', 'grant'],
        title: 'ABJD',
        venue: 'Sharjah Art Foundation',
        country: 'Sharjah, United Arab Emirates',
      },
      {
        year: 2018,
        date: '19 Jul',
        tags: ['performance'],
        title: 'Essence of Disappearance',
        venue: 'Mosaic Rooms Gallery',
        country: 'London, United Kingdom',
      },
      {
        year: 2018,
        date: '8 Jul',
        tags: ['performance'],
        title: 'C0de.03',
        venue: 'Iklectik Art Lab',
        country: 'London, United Kingdom',
      },
      {
        year: 2018,
        date: '6Jul - 15Sep',
        tags: ['exhibition', 'grant'],
        title: 'Boundaries of Multiverse',
        venue: 'Mosaic Rooms Gallery',
        country: 'London, United Kingdom',
      },
      {
        year: 2018,
        date: '6Jul - 15Sep',
        tags: ['grant'],
        title: 'Boundaries of Multiverse',
        venue: 'The British Council',
        country: 'Cairo, Egypt',
      },
      {
        year: 2018,
        date: '9 May',
        tags: ['performance'],
        title: 'The Sky is high, but!',
        venue: 'Cairotronica Symposium',
        country: 'Cairo, Egypt',
      },
      {
        year: 2018,
        date: '8 Apr',
        tags: ['performance'],
        title: 'The Sky is high, but!',
        venue: 'Cairo Contemporary Music Days',
        country: 'Cairo, Egypt',
      },
      {
        year: 2018,
        date: '1 Mar',
        tags: ['exhibition'],
        title: 'Hard Head with Soft Belly',
        venue: 'European Capital of Art',
        country: 'Valletta 2018, Malta',
      },
      {
        year: 2017,
        date: '24 Nov',
        tags: ['performance'],
        title: 'The Sky is high, but!',
        venue: 'Zaratan Arte Contemporanea',
        country: 'Lisbon, Portugal',
      },
      {
        year: 2017,
        date: '',
        tags: ['grant'],
        title: 'Sovereignty',
        venue: 'Mophradat',
        country: 'Brussels, Belgium',
      },
      {
        year: 2017,
        date: '16 Nov',
        tags: ['performance'],
        title: 'The Sky is high, but!',
        venue: 'Sorte Firkant',
        country: 'Copenhagen, Denmark',
      },
      {
        year: 2017,
        date: '8 Nov',
        tags: ['performance'],
        title: 'The Sky is high, but!',
        venue: 'Generate Festival, Shedhalle',
        country: 'Tubingen, Germany',
      },
      {
        year: 2017,
        date: '6 Nov',
        tags: ['exhibition'],
        title: 'Whose Sky is Higher?',
        venue: 'Venice Biennale Palazo Mora',
        country: 'Venice, Italy',
      },
      {
        year: 2017,
        date: '13 Apr',
        tags: ['performance'],
        title: 'The Sky is high, but!',
        venue: 'Mosaic Rooms Gallery',
        country: 'London, United Kingdom',
      },
      {
        year: 2017,
        date: '19 Jan',
        tags: ['performance'],
        title: 'CODE.20 and CODE.03',
        venue: 'LCC Screen School Launch',
        country: 'London, United Kingdom',
      },
      {
        year: 2017,
        date: '4 Dec - 11 Dec',
        tags: ['exhibition'],
        title: 'Daily Concerns',
        venue: 'Dilston Grove',
        country: 'London, United Kingdom',
      },
      {
        year: 2016,
        date: '20 Nov - 27 Nov',
        tags: ['exhibition'],
        title: 'Dry Friction',
        venue: 'OhrenHoch Gallery',
        country: 'Berlin, Germany',
      },
      {
        year: 2016,
        date: '7 Nov - 8 Nov',
        tags: ['conference'],
        title: 'Arab Cultural Fields in Crisis',
        venue: 'IREMAM (CNRS, AMU)',
        country: 'Aix-en-Provence, France',
      },
      {
        year: 2016,
        date: '3 Nov',
        tags: ['performance'],
        title: 'CODE 03',
        venue: 'Nour Festiva',
        country: 'London, United Kingdom',
      },
      {
        year: 2016,
        date: '21 Sep - 21 Sep',
        tags: ['performance'],
        title: 'CODE 03',
        venue: 'Spectrum Art  Science  Community',
        country: 'Berlin, Germany',
      },
      {
        year: 2016,
        date: '26 Aug - 27 Aug',
        tags: ['Installation'],
        title: 'Strange Umbrellas: Dry Friction',
        venue: 'SOWIESO NEUKÖLLN',
        country: 'Berlin, Germany',
      },
      {
        year: 2016,
        date: '1 Jul - 2 Jul',
        tags: ['performance'],
        title: 'Trapped Sounds',
        venue: 'AltoFest Festival',
        country: 'Naples, Italy',
      },
      {
        year: 2016,
        date: '1 Jun - 4 Jun',
        tags: ['conference'],
        title: 'Sound Art Matters',
        venue: 'Aarhus University',
        country: 'Aarhus, Denmark',
      },
      {
        year: 2016,
        date: '21 May',
        tags: ['performance'],
        title: 'Music Dispersa',
        venue: 'Iklectik Art Lab',
        country: 'London, United Kingdom',
      },
      {
        year: 2016,
        date: '16 May - 29 May',
        tags: ['exhibition'],
        title: 'NESTBOX',
        venue: 'Iklectik Art Lab',
        country: 'London, United Kingdom',
      },
      {
        year: 2016,
        date: '9 Apr',
        tags: ['performance'],
        title: 'Improvised Electronics',
        venue: 'RichMix',
        country: 'London, United Kingdom',
      },
      {
        year: 2016,
        date: '8 Apr',
        tags: ['conference'],
        title: 'Towards a Sociology of Popular Music',
        venue: 'University of Westminster',
        country: 'London, United Kingdom',
      },
      {
        year: 2016,
        date: '22 Feb - 23 Feb',
        tags: ['performance'],
        title: 'Le Crie des neurones',
        venue: 'Platform Intermedia, ',
        country: 'Nantes, France',
      },
      {
        year: 2015,
        date: '7 Apr',
        tags: ['performance'],
        title: 'Obsecration of the Overthrow',
        venue: 'Lewisham Art House',
        country: 'London, United Kingdom',
      },
      {
        year: 2015,
        date: '19 Apr',
        tags: ['performance'],
        title: 'Trapped Sounds',
        venue: 'Theater is a must',
        country: 'Alexandria, Egypt',
      },
      {
        year: 2015,
        date: '11 Mar - 12 Mar',
        tags: ['performance'],
        title: 'Trapped Sounds',
        venue: 'TownHouse Gallery',
        country: 'Cairo, Egypt',
      },
      {
        year: 2015,
        date: '11 Mar',
        tags: ['performance'],
        title: 'Trapped Sounds',
        venue: 'Goethe Institute',
        country: 'Alexandria, Egypt',
      },
      {
        year: 2015,
        date: '11 Mar',
        tags: ['grant'],
        title: 'Trapped Sounds',
        venue: 'Arab Funds for Arts and Culture',
        country: 'Beirut, Lebanon',
      },
      {
        year: 2014,
        date: '15 Nov - 16 Nov',
        tags: ['exhibition'],
        title: 'Rope Pulling',
        venue: 'PNEM Sound Art Festival',
        country: 'Uden, Netherlands',
      },
      {
        year: 2014,
        date: '8 May - 6 Jun',
        tags: ['exhibition'],
        title: 'Rope Pulling',
        venue: 'AfroPixel 4 Festival',
        country: 'Dakar, Senegal',
      },
      {
        year: 2013,
        date: '29 Aug - 20 Sep',
        tags: ['exhibition'],
        title: 'SAWTYAT',
        venue: 'Bibliotheca of Alexandria',
        country: 'Alexandria, Egypt',
      },
      {
        year: 2013,
        date: '29 Aug - 20 Sep',
        tags: ['exhibition'],
        title: 'SAWTYAT',
        venue: 'street',
        country: 'Damietta, Egypt',
      },
      {
        year: 2012,
        date: '3 Nov',
        tags: ['performance'],
        title: 'End of Kush',
        venue: 'D4Net',
        country: 'Amsterdam, Netherlands',
      },
    ],
    press: [
      {
        date: '22.5.2019',
        year: 2019,
        publisher: 'United Kingdoma.dk ',
        title: ' Lyd performance af Khaled Kaddal',
        tags: [],
      },
      {
        date: '7.4.2019',
        year: 2019,
        publisher: 'Overgarden Institut for Samtidskunst',
        title: 'To The Nostrils of Time ',
        author: 'Salomé Voegelin',
        tags: [],
      },
      {
        date: '6.4.2019',
        year: 2019,
        publisher: 'Art Week',
        title: 'Khaled Kaddal: To the Nostrils of Time',
        tags: [],
      },
      {
        date: '6.4.2019',
        year: 2019,
        publisher: 'Frieze ',
        title: 'Khaled Kaddal: To the Nostrils of Time',
        tags: [],
      },
      {
        date: '6.4.2019',
        year: 2019,
        publisher: 'Kunsten',
        title: 'Khaled Kaddal: To The Nostrils of Time',
        tags: [],
      },
      {
        date: '20.2.2019',
        year: 2019,
        publisher: 'Mophradat Foundation ',
        title: 'Rapturous Inspirations ',
        author: 'Nesrine Mansour',
        tags: [],
      },
      {
        date: '9.12.2018',
        year: 2018,
        publisher: 'The American University in Cairo ',
        title: 'Music, Sound and Built Environment ',
        tags: [],
      },
      {
        date: '29.9.2018',
        year: 2018,
        publisher: 'Sharjah Art Foundation',
        title: 'Ten Years of Production Programme',
        tags: [],
      },
      {
        date: '15.9.2018',
        year: 2018,
        publisher: 'World Art Foundations',
        title: 'The Mosaic Rooms: What do you mean here we are?',
        tags: [],
      },
      {
        date: '10.9.2018',
        year: 2018,
        publisher: 'Arab British Centre ',
        title: 'What do you mean here we are?',
        tags: [],
      },
      {
        date: '31.8.2018',
        year: 2018,
        publisher: 'Apollo Magazine',
        title: 'The remarkable rebirth of the Townhouse Gallery',
        author: ' Amina Diab',
        tags: [],
      },
      {
        date: '13.7.2018',
        year: 2018,
        publisher: 'Financial Times',
        title: 'The tumultuous journey of Cairo’s Townhouse Gallery',
        author: ' Rachel Spence',
        tags: [],
      },
      {
        date: '13.7.2018',
        year: 2018,
        publisher: 'Goethe Institute',
        title: 'MEINE STIMME... DEINE STIMME... UNSERE STIMME',
        author: ' Eslam Anwar',
        tags: [],
      },
      {
        date: '10.7.2018',
        year: 2018,
        publisher: 'Kunst Aspekte',
        title: 'What do you mean here we are?',
        tags: [],
      },
      {
        date: '6.7.2018',
        year: 2018,
        publisher: 'ArtDaily ',
        title: 'A retrospective of the renowned Townhouse Gallery',
        tags: [],
      },
      {
        date: '2.6.2018',
        year: 2018,
        publisher: 'Österreichischer Rundfunk',
        title: 'Oe1',
        author: ' Elstner Rainer',
        tags: [],
      },
      {
        date: '26.11.2017',
        year: 2017,
        publisher: '104.4 Resonance FM',
        title: 'Come here, come down',
        author: ' Ilia Rogatchevski',
        tags: [],
      },
      {
        date: '20.10.2017',
        year: 2017,
        publisher: 'Generate! für elektronische Kunste',
        title: 'Khaled Kaddal: Code.03 ',
        tags: [],
      },
      {
        date: '1.6.2017',
        year: 2017,
        publisher: 'The Wire magazine',
        title: 'Khaled Kaddal ',
        author: '  Ilia Rogatchevski',
        tags: [],
      },
      {
        date: '17.5.2017',
        year: 2017,
        publisher: 'Majalla',
        title: 'Khaled Kaddal: Sculpting Sounds and Power',
        author: ' Joud Halawani Al-Tamimi',
        tags: [],
      },
      {
        date: '15.11.2016',
        year: 2016,
        publisher: 'Museum of Portable Sound',
        title: 'Field-recordings #2011',
        tags: [],
      },
      {
        date: '2.11.2016',
        year: 2016,
        publisher: 'University of Marburg and IREMAM (FR)',
        title: 'Arab Cultural Fields in Crisis: Sound and Traumas',
        author: 'Youssef El-Chazly',
        tags: [],
      },
      {
        date: '5.5.2016',
        year: 2016,
        publisher: 'Aarhus University ',
        title: 'Sound Art Matters: Soundscape of political conflicts',
        tags: [],
      },
      {
        date: '19.4.2016',
        year: 2016,
        publisher: 'Mada Masr ',
        title: 'Q&A on why Theater is a Must in Alexandria',
        author: ' Rowan El-Shimi',
        tags: [],
      },
      {
        date: '11.4.2016',
        year: 2016,
        publisher: 'Westminster University ',
        title: 'Towards a Sociology of Popular Music ',
        author: 'Youssef El-Chazly',
        tags: [],
      },
      {
        date: '24.3.2016',
        year: 2016,
        publisher: 'Ibraaz channel ',
        title: 'The Dance ',
        tags: [],
      },
      {
        date: '21.10.2015',
        year: 2015,
        publisher: 'The OutPost ',
        title: 'White Noise ',
        author: ' Maryam Boctor',
        tags: [],
      },
      {
        date: '5.7.2015',
        year: 2015,
        publisher: 'Radio Happening',
        title: 'We are all radio creatures',
        author: ' Anna Raimondo',
        tags: [],
      },
      {
        date: '15.3.2015',
        year: 2015,
        publisher: 'Ahram Online',
        title: 'Egyptian artist embraces the anarchy of intangible sound',
        author: ' Heba El-Sherif',
        tags: [],
      },
      {
        date: '4.11.2014',
        year: 2014,
        publisher: ' ',
        title: 'Pnem Sound Art Festival',
        tags: [],
      },
      {
        date: '10.9.2014',
        year: 2014,
        publisher: 'Radio Panik Radio',
        title: 'Dakar Radio ',
        tags: [],
      },
      {
        date: '10.7.2014',
        year: 2014,
        publisher: 'Agora sol radio',
        title: 'The self-noise ',
        tags: [],
      },
      {
        date: '3.6.2014',
        year: 2014,
        publisher: 'Au Senegal',
        title: 'Un Atelier ouvert diffusé sur la webradio JangOut ',
        author: ' Press',
        tags: [],
      },
      {
        date: '8.5.2014',
        year: 2014,
        publisher: 'C& ',
        title: 'Jardins de Resistance ',
        tags: [],
      },
      {
        date: '22.4.2014',
        year: 2014,
        publisher: 'Afropixel ',
        title: 'Programme Festival Afropixel#4 ',
        tags: [],
      },
      {
        date: '20.11.2013',
        year: 2013,
        publisher: 'Jeunesses Arabes, La Découverte ',
        title: 'Alexandrins en Fusion ',
        author: ' Youssef Chazly',
        tags: [],
      },
    ],
  },
]
