import React from 'react'
import styled from 'styled-components'
import { Theme } from '../theme/theme.style'

function HorizontalLines() {
  return (
    <div>
      <LeftLine />
      <RightLine />
    </div>
  )
}

const LeftLine = styled.div`
  position: fixed;
  background-color: ${Theme.colors.accent};
  width: 20vw;
  height: 1px;
  top: 50%;
  transform: translateY(-50%);
  left: 0;

  @media (max-width: 1179px) {
    width: 10vw;
  }

  @media (max-width: 1000px) {
    display: none;
  }
`
const RightLine = styled.div`
  position: fixed;
  background-color: ${Theme.colors.accent};
  width: 20vw;
  height: 1px;
  top: 50%;
  transform: translateY(-50%);
  right: 0;

  @media (max-width: 1179px) {
    width: 10vw;
  }

  @media (max-width: 1000px) {
    display: none;
  }
`

export default HorizontalLines
