import React from 'react'
import styled from 'styled-components'
import { ProjectCard } from '../components'
import { projectsData } from '../data/projects.data'
import HorizontalLines from '../components/HorizontalLines/HorizontalLines'

function Projects() {
  const projects = projectsData

  return (
    <Section>
      <HorizontalLines />
      {projects.map((project, index) => (
        <ProjectCard key={index} project={project} />
      ))}
    </Section>
  )
}

export default Projects

const Section = styled.div`
  display: flex;
  width: 100%;
  margin: 5rem 0;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
