import styled from 'styled-components'
import { Theme } from '../theme/theme.style'

export const Stripe = styled.div`
  position: absolute;
  width: 100%;
  height: 5%;
  top: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background-color: black;
  border: thin solid #40e0d080;
  border-left: 0;
  border-right: 0;

  h3 {
    color: #40e0d0;
    letter-spacing: 0.2rem;
  }
  &:hover {
    border: thin solid ${Theme.colors.accent};
    box-shadow: 0px 0px 7px 0.5px ${Theme.colors.minor};
  }

  @media (max-width: 768px) {
    z-index: -1;
  }
`
