import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Artwork } from '../../store/type/artwork.type'
import VideoCard from './VideoCard'

interface FuncProp {
  title: string
  bucket: string
  total: number
  contractAddress?: string | undefined
}

function VideoList({ title, bucket, total, contractAddress }: FuncProp) {
  const [videoArtworks, setVideoArtworks] = useState<Artwork[] | null>(null)

  const getVideoArtworks = () => {
    const artworks: Artwork[] = []

    for (let i = 0; i < total; i++) {
      const artworkNumber = i + 1
      artworks.push({
        title: `${title} #${artworkNumber}`,
        image: `${bucket}/${artworkNumber}.mp4`,
        edition: 5,
        url: `https://opensea.io/assets/ethereum/${contractAddress}/${artworkNumber}`,
      } as Artwork)
    }

    setVideoArtworks(artworks)
  }

  useEffect(() => {
    getVideoArtworks()
  }, [videoArtworks])

  return (
    <Grid>
      {videoArtworks?.map((videoArtwork, index) => (
        <VideoCard
          key={index}
          id={index}
          title={videoArtwork.title}
          edition={videoArtwork.edition}
          price={0.5}
          video={videoArtwork.image}
          url={videoArtwork.url}
        />
      ))}
    </Grid>
  )
}

export default VideoList

const Grid = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem 2rem;
  margin: 3rem 5rem;
  justify-content: center;
  align-content: center;
  @media (max-width: 1150px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (max-width: 768px) {
    width: 95%;
    grid-template-columns: repeat(1, 1fr);
  }
`
