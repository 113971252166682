import React from 'react'
import styled from 'styled-components'
import { collectionsData } from '../../data/collections.data'
import CollectionCard from './collectionCard'
import HorizontalLines from '../HorizontalLines/HorizontalLines'

function CollectionList() {
  const collections = collectionsData

  return (
    <Section>
      <HorizontalLines />
      {collections.map((collection) => (
        <CollectionCard key={collection.id} collection={collection} />
      ))}
    </Section>
  )
}

export default CollectionList

const Section = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem 0;
  min-height: 100vh;
  margin-bottom: 4rem;
  flex-direction: column;
  align-items: center;
  justify-content: start;
`
