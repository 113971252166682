import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import {
  Collection,
  CollectionKeys,
  StatusKeys,
} from '../../store/type/collection.type'
import { Theme } from '../theme/theme.style'

interface FuncProps {
  collection: Collection
}

const CollectionCard = ({ collection }: FuncProps) => {
  const navigate = useNavigate()

  return (
    <CardContainer>
      <Content>
        <SubContent
          content={true}
          onClick={() =>
            collection[CollectionKeys.STATUS] === StatusKeys.DEPLOYED &&
            navigate(`/collection/${collection[CollectionKeys.ID]}`)
          }
        >
          <Title>{collection[CollectionKeys.TITLE]}</Title>
          <span></span>
          <Tags>
            {collection[CollectionKeys.SUPPORTED_BY].map(
              (organisation, index) => (
                <>
                  <Tag>| {organisation.name}</Tag>
                </>
              ),
            )}
          </Tags>
        </SubContent>
      </Content>

      <VideoContainer
        comingSoon={
          collection[CollectionKeys.STATUS] === StatusKeys.IN_PROGRESS
        }
        onClick={() =>
          collection[CollectionKeys.STATUS] === StatusKeys.DEPLOYED &&
          navigate(`/collection/${collection[CollectionKeys.ID]}`)
        }
      >
        {collection[CollectionKeys.STATUS] === StatusKeys.IN_PROGRESS && (
          <Title>Coming soon!</Title>
        )}

        <video
          src={collection[CollectionKeys.MAIN_VIDEO]}
          autoPlay
          loop
          muted
        />
      </VideoContainer>
    </CardContainer>
  )
}

export default CollectionCard

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 4rem;
  max-width: 800px;

  &:hover {
    border: thin solid ${Theme.colors.accent};
    box-shadow: 0px 0px 7px 0.5px ${Theme.colors.minor};
  }

  @media (max-width: 768px) {
    border: thin solid ${Theme.colors.accent};
    box-shadow: 0px 0px 7px 0.5px ${Theme.colors.minor};
    max-width: 90%;
  }
`

const Title = styled.h3`
  margin: 0;
  color: ${Theme.colors.accent};
  letter-spacing: 3px;
`

const VideoContainer = styled.div<{ comingSoon: boolean }>`
  display: flex;
  position: relative;
  width: 100%;
  height: 50vh;
  overflow: hidden;
  border: thin solid ${Theme.colors.accent};
  justify-content: center;
  align-items: center;

  video {
    opacity: ${(props) => (props.comingSoon ? `35%` : `100%`)};
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: -1;
  }

  h3 {
    font-size: 22px;
    letter-spacing: 2px;
  }

  @media (max-width: 768px) {
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 35vh;

    h3 {
      font-size: 20px;
    }

    video {
      width: auto;
      height: 100%;
      object-fit: cover;
    }
  }
`
const Tags = styled.span`
  display: flex;
  flex-wrap: wrap;
`

const Tag = styled.span`
  color: ${Theme.colors.minor};
  padding: 8px 8px 0px 0;
  border-radius: 4px;
  font-weight: 100;
  font-size: 13px;
  letter-spacing: 1px;
`

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`

const SubContent = styled.div<{ content: boolean }>`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  padding: 1rem;
  border: ${(props) => props.content && `thin solid ${Theme.colors.accent}`};

  gap: 0.5rem;
  align-items: ${(props) => props.content && `center`};
  justify-content: center;

  &:hover {
    border: thin solid ${Theme.colors.accent};
    box-shadow: 0px 0px 7px 0.5px ${Theme.colors.minor};
    background-color: #ad252180;
  }

  @media (max-width: 768px) {
    align-items: flex-start;
    justify-content: flex-start;
    flex-basis: 100%;
  }
`
