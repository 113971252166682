import React from 'react'
import styled from 'styled-components'
import { Theme } from './theme/theme.style'

const FooterContainer = styled.nav`
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 10px 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 25px;
  z-index: 999;
  background-color: ${Theme.colors.major};

  border: thin solid ${Theme.colors.accent_soft};

  &:hover {
    border: thin solid ${Theme.colors.accent};
    box-shadow: 0px 0px 7px 0.5px ${Theme.colors.minor};
  }

  @media (max-width: 768px) {
    display: none;
  }
`

const Tag = styled.p`
  color: ${Theme.colors.minor};
  margin: 0rem 0.7rem;
  white-space: nowrap;
  font-weight: 100;
  font-size: 12px;
`

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <Tag>© 2023 All rights reserved by Heru Studio Ltd.</Tag>
    </FooterContainer>
  )
}

export default Footer
