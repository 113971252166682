import React from 'react'
import { GlobalStyle, Theme } from './components'
import Main from './pages/main.page'
import About from './pages/about.page'
import Navbar from './components/navbar/index'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import Footer from './components/Footer'
import Projects from './pages/projects.page'
import CollectionPage from './pages/collection.page'
import CollectionList from './components/collection/CollectionList'
import ProjectPage from './pages/project.page'

function App() {
  const items = [
    { label: 'Terminal', link: '/' },
    { label: 'Projects', link: '/project' },
    { label: 'Collections', link: '/collection' },
    { label: 'About', link: '/about' },
  ]

  return (
    <div>
      <GlobalStyle theme={Theme} />
      <Navbar items={items} />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/about" element={<About />} />
          <Route path="/project" element={<Projects />} />
          <Route path="/project/:id" element={<ProjectPage />} />
          <Route path="/collection" element={<CollectionList />} />
          <Route path="/collection/:id" element={<CollectionPage />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  )
}

export default App
