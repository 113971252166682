import styled from 'styled-components'
import { Theme } from './theme.style'

// FONTS
export const H1 = styled.h1`
  color: ${Theme.colors.accent};
`

export const H2 = styled.h2`
  color: ${Theme.colors.accent};
`

// export const H3 = styled.h3`
//   color: ${Theme.colors.accent};
// `

export const H3 = styled.h3`
  color: ${Theme.colors.accent};
  letter-spacing: 3px;
`

export const P = styled.p`
  color: ${Theme.colors.minor};
  font-weight: 100;
  margin: 1rem;
  line-height: 1.5rem;
  text-align: justify;

  @media (min-width: 768px) {
    font-size: 16px;
  }
`

export const TD = styled.td`
  color: ${Theme.colors.minor};
  font-weight: 100;
  font-size: 14px;
  text-align: left;

  @media (min-width: 768px) {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

export const Tag = styled.p`
  color: ${Theme.colors.accent};
  margin: 0rem 0.7rem;
  white-space: nowrap;
  @media (max-width: 768px) {
    margin: 0rem 0.2rem;
    font-size: 14px;
  }
`
