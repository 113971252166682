import styled from 'styled-components'
import { Theme } from './theme.style'

export const Button = styled.button`
  border: thin solid ${Theme.colors.accent_soft};
  text-align: center;
  text-decoration: none;
  display: inline-block;
  padding: 15px 32px;
  color: ${Theme.colors.minor};
  background-color: black;
  letter-spacing: 2px;

  &:hover {
    border: thin solid ${Theme.colors.accent};
    box-shadow: 0px 0px 7px 0.5px ${Theme.colors.minor};
    background-color: #ad252180;
  }
`
