import React from 'react'
import styled from 'styled-components'
import { Theme } from '../theme/theme.style'

interface FuncProps {
  image: string
}

const GalleryCard = ({ image }: FuncProps) => {
  return (
    // <CardContainer>
    <ImageContainer>
      <img src={image} />
    </ImageContainer>
    // </CardContainer>
  )
}

export default GalleryCard

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  img {
    justify-content: center;
    align-items: center;
    object-fit: contain;
    width: 100%;
  }

  border: thin solid ${Theme.colors.accent_soft};
  &:hover {
    border: thin solid ${Theme.colors.accent};
    box-shadow: 0px 0px 7px 0.5px ${Theme.colors.minor};
  }

  @media (max-width: 768px) {
    border: thin solid ${Theme.colors.accent};
    box-shadow: 0px 0px 7px 0.5px ${Theme.colors.minor};
  }
`
