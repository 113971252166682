import React from 'react'
import styled from 'styled-components'
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa'
import { Links } from '../../store/type/artist.type'
import { Theme } from '../theme/theme.style'
interface FuncProps {
  image: string
  links: Links
}

const SocialMedia = styled.div`
  margin: 0rem 0.5rem;
  padding: 0.5rem;
  margin: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`

const Icon = styled.span`
  margin: 0rem 0.5rem;
  font-size: 1.2rem;
  color: ${Theme.colors.accent};

  &:hover {
    color: ${Theme.colors.minor};
  }
`

const ContactCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 25%;
  img {
    align-self: center;
    width: 100%;
    padding: 0.5rem;
    border: thin solid ${Theme.colors.accent_soft};
    box-shadow: 0px 0px 7px 0.5px ${Theme.colors.minor};
  }

  img:hover {
    border: thin solid ${Theme.colors.accent};
    box-shadow: 0px 0px 10px 0.5px ${Theme.colors.minor};
  }

  @media (max-width: 1210px) {
    width: 65%;
  }
`

function ProfileCard(props: FuncProps) {
  const { image, links } = props

  return (
    <ContactCard>
      <img
        src={image}
        alt="khaledkaddal"
        style={{
          width: '100%',
          height: 'auto',
          maxWidth: '1200px',
        }}
      />

      <SocialMedia>
        <a href={`${links.facebook}`} target="_blank" rel="noreferrer">
          <Icon>
            <FaFacebook />
          </Icon>
        </a>
        <a href={`${links.twitter}`} target="_blank" rel="noreferrer">
          <Icon>
            <FaTwitter />
          </Icon>
        </a>
        <a href={`${links.instagram}`} target="_blank" rel="noreferrer">
          <Icon>
            <FaInstagram />
          </Icon>
        </a>
      </SocialMedia>
    </ContactCard>
  )
}

export default ProfileCard
