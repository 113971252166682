import { GlobalStyle } from './theme/global.style'
import { Theme } from './theme/theme.style'
import { H3, P, Tag } from './theme/fonts.style'
import ProjectCard from './project/projectCard'

import { Section } from './section/section.style'
import { Stripe } from './stripe/stripe.style'
import { Container } from './container/container.style'
import BackgroundVideo from './background/BackgroundVideo'
import ProfileCV from './about/profileCV/profileCV'
import ProfileCard from './about/profileCard.component'

export {
  GlobalStyle,
  Theme,
  Section,
  Stripe,
  Container,
  H3,
  P,
  BackgroundVideo,
  Tag,
  ProfileCard,
  ProfileCV,
  ProjectCard,
}
