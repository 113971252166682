import React from 'react'
import styled from 'styled-components'
import { Theme } from '../theme/theme.style'

interface FuncProps {
  description: string
}

const Description = ({ description }: FuncProps) => {
  return (
    <DescriptionContainer>
      <Paragraph>{description}</Paragraph>
    </DescriptionContainer>
  )
}

export default Description

const DescriptionContainer = styled.div`
  margin: 1rem;
  border: thin solid ${Theme.colors.accent};
  box-shadow: 0px 0px 7px 0.5px ${Theme.colors.minor};
  width: 65%;
  text-align: center;

  // padding: 0.5rem;

  @media (max-width: 768px) {
    margin-top: 1rem;
    width: 95%;
  }
`
const Paragraph = styled.p`
  padding: 0 1rem;
  color: ${Theme.colors.minor};
  font-size: 16px;
  line-height: 1.5;
  white-space: pre-wrap;
  // text-align: justify;
  font-weight: 300;
  letter-spacing: 1px;
`
