import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import AboutCollection from '../components/collection/AboutCollection'
import ArtworkList from '../components/collection/ArtworkList'
import VideoList from '../components/collection/VideoList'
import { collectionsData } from '../data/collections.data'
import { Collection, CollectionKeys } from '../store/type/collection.type'

const Section = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem 0;
  height: auto;
  margin-bottom: 4rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const NftPage = () => {
  const { id } = useParams()
  const [collection, setCollection] = useState<Collection>()

  const getCollection = () => {
    const collection = collectionsData.find(
      (collection) => collection[CollectionKeys.ID] === id,
    ) as Collection

    setCollection(collection)
  }

  useEffect(() => {
    getCollection()
  }, [id])

  return (
    <Section>
      {collection != null && (
        <>
          <AboutCollection
            title={collection.title}
            artworks={collection.numberOfArtworks}
            editions={collection.numberOfEditions}
            description={collection.description}
            marketplaces={collection.marketplaces}
          />

          {/* VIDEOS */}

          <VideoList
            title={collection.title}
            bucket={collection.artworks}
            total={6}
            contractAddress={collection.contractAddress}
          />

          {/* IMAGES */}
          <ArtworkList
            title={collection.title}
            bucket={collection.artworks}
            total={100}
            contractAddress={collection.contractAddress}
          />
        </>
      )}
    </Section>
  )
}

export default NftPage
