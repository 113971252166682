import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

interface IProps {
  src: string
  isMuted: boolean
}

const BackgroundVideo = ({ src, isMuted }: IProps) => {
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (videoRef.current != null) {
      videoRef.current.volume = isMuted ? 0 : 0.5
    }
  }, [isMuted])

  return (
    <VideoContainer>
      <video
        ref={videoRef}
        src={src}
        autoPlay
        loop
        muted={isMuted}
        playsInline
        preload="auto"
      />{' '}
    </VideoContainer>
  )
}

export default BackgroundVideo

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  video {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  @media (max-width: 768px) {
    position: fixed;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    video {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
`
