import React, { useState } from 'react'
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa'
import styled from 'styled-components'
import { Links } from '../../store/type/artist.type'
import Footer from '../Footer'
import { Theme } from '../theme/theme.style'

const NavbarContainer = styled.nav`
  display: flex;
  justify-content: center;
  padding: 20px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  @media (min-width: 768px) {
    display: none;
  }
`

const NavbarToggle = styled.div`
  cursor: pointer;
  position: fixed;
  display: block;

  @media (min-width: 768px) {
    display: none;
  }
`

const HamburgerList = styled.ul`
  display: flex;
  flex-direction: column;
  background-color: ${Theme.colors.major};
  height: 75vh;
  width: 95vw;
  margin: 5rem 0;
  padding: 5rem;
  justify-content: center;
  align-content: center;

  border: thin solid ${Theme.colors.accent};
  box-shadow: 0px 0px 7px 0.5px ${Theme.colors.minor};
  opacity: 90%;
  position: fixed;

  @media (min-width: 768px) {
    display: none;
  }
`

const NavbarListItem = styled.li`
  margin: 10px;
  padding: 10px;
  border: thin solid ${Theme.colors.accent};
  box-shadow: 0px 0px 7px 0.5px ${Theme.colors.minor};
  text-align: center;
`

const NavbarLink = styled.a`
  color: ${Theme.colors.minor};
  letter-spacing: 1px;

  &:hover {
    color: ${Theme.colors.accent};
  }
`

const HamburgerMenu = styled.div`
  cursor: pointer;
  background: black;
  display: block;
  border: thin solid ${Theme.colors.accent};
  box-shadow: 0px 0px 7px 0.5px ${Theme.colors.minor};

  @media (min-width: 768px) {
    display: none;
  }
`

const HamburgerLine = styled.div`
  width: 30px;
  height: 3px;
  background-color: ${Theme.colors.accent};
  margin: 5px;
  transition: 0.4s;
`

interface Props {
  items: Array<{ label: string; link: string }>
  links: Links
}

const HamburgerBar: React.FC<Props> = ({ items, links }) => {
  const [isOpen, setOpen] = useState(false)

  return (
    <NavbarContainer>
      <NavbarToggle onClick={() => setOpen(!isOpen)}>
        <HamburgerMenu>
          <HamburgerLine />
          <HamburgerLine />
          <HamburgerLine />
        </HamburgerMenu>
      </NavbarToggle>

      {isOpen && (
        <HamburgerList>
          <div>
            {items.map(({ label, link }) => (
              <NavbarListItem key={link}>
                <NavbarLink href={link}>{label}</NavbarLink>
              </NavbarListItem>
            ))}
          </div>
          <div>
            <SocialMedia>
              <a href={`${links.facebook}`} target="_blank" rel="noreferrer">
                <Icon>
                  <FaFacebook />
                </Icon>
              </a>
              <a href={`${links.twitter}`} target="_blank" rel="noreferrer">
                <Icon>
                  <FaTwitter />
                </Icon>
              </a>
              <a href={`${links.instagram}`} target="_blank" rel="noreferrer">
                <Icon>
                  <FaInstagram />
                </Icon>
              </a>
            </SocialMedia>
            <FooterContainer>
              <Tag>© 2023 All rights reserved by Heru Studio Ltd.</Tag>
            </FooterContainer>
          </div>
        </HamburgerList>
      )}
    </NavbarContainer>
  )
}

export default HamburgerBar

const SocialMedia = styled.div`
  display: inline-block;
  margin: 0rem 0.5rem;
  padding: 0.5rem;
  margin: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`

const Icon = styled.span`
  margin: 0rem 0.5rem;
  font-size: 1.2rem;
  color: ${Theme.colors.accent};

  &:hover {
    color: ${Theme.colors.minor};
  }
`

const Tag = styled.p`
  color: ${Theme.colors.minor};
  white-space: nowrap;
  font-weight: 100;
  font-size: 12px;
`

const FooterContainer = styled.nav`
  display: flex;
  /* margin-top: 2rem; */
  justify-content: center;
  bottom: 0;
`
