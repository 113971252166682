import React from 'react'
import styled from 'styled-components'
import GalleryCard from './GalleryCard'

interface FuncProp {
  images: string[]
}

function GalleryList({ images }: FuncProp) {
  return (
    <Grid>
      {images.map((img, index) => (
        <GalleryCard key={index} image={img} />
      ))}
    </Grid>
  )
}

export default GalleryList

export const Grid = styled.div`
  width: 65%;
  display: inline-grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem 1rem;
  margin: 1rem 5rem;
  justify-content: center;
  align-content: center;
  @media (max-width: 1150px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 768px) {
    width: 95%;
    grid-template-columns: repeat(1, 1fr);
  }
`
