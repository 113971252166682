import { Artwork } from './artwork.type'
import { Organisation } from './organisation.type'

export enum CollectionKeys {
  ID = 'id',
  TITLE = 'title',
  DESCRIPTION = 'description',
  YEAR = 'year',
  MAIN_VIDEO = 'mainVideo',
  TAGS = 'tags',
  SUPPORTED_BY = 'supportedBy',
  STATUS = 'status',
  DEPLOYED_ON = 'deployedOn',
  NUMBER_OF_ARTWORKS = 'numberOfArtworks',
  NUMBER_OF_EDITIONS = 'numberOfEditions',
  ARTWORKS = 'artworks',
  MARKETPLACES = 'marketplaces',
  CONTRACT_ADDRESS = 'contractAddress',
}

export enum MarketplacesKeys {
  NAME = 'name',
  URL = 'url',
}

export interface Marketplace {
  [MarketplacesKeys.NAME]: string
  [MarketplacesKeys.URL]: string
}

export enum StatusKeys {
  DEPLOYED = 'deployed',
  IN_PROGRESS = 'inProgress',
}

export interface Collection {
  [CollectionKeys.ID]: string
  [CollectionKeys.TITLE]: string
  [CollectionKeys.DESCRIPTION]: string
  [CollectionKeys.YEAR]: number
  [CollectionKeys.MAIN_VIDEO]: string
  [CollectionKeys.TAGS]: string[]
  [CollectionKeys.STATUS]: StatusKeys
  [CollectionKeys.SUPPORTED_BY]: Organisation[]
  [CollectionKeys.NUMBER_OF_ARTWORKS]: number
  [CollectionKeys.NUMBER_OF_EDITIONS]: number
  [CollectionKeys.ARTWORKS]: string
  [CollectionKeys.CONTRACT_ADDRESS]?: string
  [CollectionKeys.MARKETPLACES]?: Marketplace[]
}
