import React from 'react'
import { Theme } from '../theme/theme.style'
import styled from 'styled-components'
interface FuncProps {
  videoLink: string
}

const VideoComponent = ({ videoLink }: FuncProps) => {
  return (
    <VideoContainer>
      <video controls>
        <source src={videoLink} type="video/mp4" />{' '}
      </video>{' '}
    </VideoContainer>
  )
}

export default VideoComponent

const VideoContainer = styled.div`
  margin: 1rem;
  border: thin solid ${Theme.colors.accent};
  box-shadow: 0px 0px 7px 0.5px ${Theme.colors.minor};
  width: 65%;
  text-align: center;

  // padding: 0.5rem;

  video {
    // position: absolute;
    width: 100%;
    height: auto;
    // top: 50%;
    // left: 50%;
    // transform: translateX(-50%) translateY(-50%);
  }

  @media (max-width: 768px) {
    margin-top: 1rem;
    width: 95%;
  }
`
